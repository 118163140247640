export default {
  notAllowTitle: "Impossible",
  notAllowText: "Vous n'avez pas les droits d'effectuer cette action",
  allNotAllowText:
    "Cet élément est fourni en standard par APS Bénin. Il n'est ni supprimable, ni modifiable.",
  broker_inspector: {
    slips: {
      show: true,
      create: false,
      all: true,
      update: false,
      delete: false,
    },
    prospect_client: {
      show: true,
      all: true
    },
    project_contrat: {
      show: true,
      all: true,
      update:true
    },
    stats_team: {
      all: true,
    },
    ongoing_project: {
      delete:true,
      update: true,
      show: true,
      all: true,
    },
  },
  broker_leader: {
    slips: {
      show: true,
      create: false,
      all: true,
      update: false,
      delete: false,
    },
    prospect_client: {
      show: true,
      all: true
    },
    project_contrat: {
      show: true,
      all: true,
      update:true

    },
    stats_team: {
      all: true,
    },
    ongoing_project: {
      delete:true,
      update: true,
      show: true,
      all: true,
    },
  },
  broker_merchant: {
    slips: {
      show: true,
      create: false,
      all: true,
      update: false,
      delete: false,
    },
    prospect_client: {
      show: true,
      all: true
    },
    project_contrat: {
      show: true,
      all: true,
      update:true
    },
    stats_team: {
      all: false,
    },
    ongoing_project: {
      delete:true,
      update: true,
      show: true,
      all: true,
    },
  },
  broker_general_agent: {
    slips: {
      show: true,
      create: false,
      all: true,
      update: false,
      delete: false,
    },
    prospect_client: {
      show: true,
      all: true
    },
    project_contrat: {
      show: true,
      all: true,
      update:true
    },
    stats_team: {
      all: false,
    },
    ongoing_project: {
      delete:true,
      update: true,
      show: true,
      all: true,
    },
  },
  broker: {
    slips: {
      show: true,
      create: false,
      all: true,
      update: false,
      delete: false,
    },
    prospect_client: {
      show: true,
      all: true
    },
    project_contrat: {
      show: true,
      all: true,
      update:true
    },
    stats_team: {
      all: false,
    },
    ongoing_project: {
      delete:true,
      update: true,
      show: true,
      all: true,
    },
  },
}
