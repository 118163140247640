export default {
  ongoing_project: {
    relance: {
      success: {
        color: "success",
        text: "Relance envoyée avec succes",
        title: "Relancer"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'envoie de la relance'",
        title: "Relancer"
      }
    },
    close: {
      success: {
        color: "success",
        text: "Projet fermé avec succes",
        title: "Mis à jour"
      },
      error: {
        color: "danger",
        text: " Erreur lors de la fermeture du projet",
        title: "Mis à jour"
      }
    }
  },
  users: {
    mdp: {
      success: {
        color: "success",
        text: "Mot de passe a été changé avec succès",
        title: "Changement du mot de passe"
      },
      error: {
        color: "danger",
        text: "Ancien mot de passe incorrect",
        title: "Changement du mot de passe"
      }
    },
    updateUser: {
      success: {
        color: "success",
        text: "Les informations ont été mises à jour avec succès",
        title: "Mise à jour profil"
      },
      error: {
        color: "danger",
        text: "Une erreur s'est produite",
        title: "Mise à jour profil"
      }
    },
    updateAvatar: {
      success: {
        color: "success",
        text: "Image a été téléchargée avec succès",
        title: "Mise à jour avatar"
      },
      error: {
        color: "danger",
        text: "Une erreur s'est produite",
        title: "Mise à jour profil"
      }
    },
    notFound: {
      error: {
        color: "danger",
        text: "Le nom d'utilisateur n'existe pas",
        title: "Utilisateur"
      },
    },
    nameExist: {
      error: {
        color: "danger",
        text: "Nom d'utilisateur existe déjà",
        title: "Utilisateur"
      },
    },
    mailError: {
      error: {
        color: "danger",
        text: "Impossible d'envoyer le mail à l'adresse",
        title: "Mail"
      },
    },
  },
  mailing: {
    file: {
      success: {
        color: "success",
        text: "Fichier a été ajouté avec succès",
        title: "Fichier"
      },
      error: {
        color: "danger",
        text: "Erreur lors de l'ajout du fichier",
        title: "Fichier"
      }
    },
  },
};
