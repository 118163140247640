export default {
  pages: {
    key: "title",
    data: [
      {
        url: "/",
        title: "Acceuil",
        is_bookmarked: true,
        icon: "HomeIcon",
      },
      /*{
        url: "/settings",
        title: "Paramètres",
        is_bookmarked: true,
        icon: "SettingsIcon",
      },*/
    ]
  }
}
